@import "@styles/_variables.scss";
.hero {
  position: fixed;
  top: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  background: #372f2f;
  z-index: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 Max(112px, calc((100% - 1440px) / 2 + 112px));

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 0 20px;
  }

  &.not-found {
    align-items: flex-start;

    & > h2 {
      text-align: left;
    }

    & > .ab-link {
      color: #fff !important;
    }
  }

  & > video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-size: cover;
    z-index: -1;
    object-fit: cover;
    align-self: center;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: $tablet-breakpoint) {
      &.web {
        display: none;
      }
      &.mobile {
        display: flex;
        width: 100%;
      }
    }
  }
  & > .volume-web {
    background-color: transparent;
    position: absolute;
    bottom: 120px;
    right: 20px;
    z-index: 9999;
    cursor: pointer;
    @media screen and (max-width: $tablet-breakpoint) {
      display: none;
    }
  }
  & > .volume-mobile {
    display: none;

    @media screen and (max-width: $tablet-breakpoint) {
      background-color: transparent;
      display: flex;
      position: absolute;
      right: 15px;
      bottom: 100px;
      z-index: 999;
    }
  }

  & > span {
    #hero-mobile {
      display: none !important;

      @media screen and (max-width: 850px) {
        display: block !important;
      }
    }

    #hero-desktop {
      @media screen and (max-width: 850px) {
        display: none !important;
      }
    }
  }

  // & > picture {
  //   align-items: center;
  //   justify-content: center;

  //   & > img {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     height: 100%;
  //     width: 100%;
  //     opacity: 0.6;
  //     background-size: cover;
  //     z-index: -1;
  //     object-fit: cover;
  //     background-position: center;
  //   }
  // }

  & > h2.hero-text {
    color: #fff;
    width: 100%;
    max-width: 710px;
  }

  & > h2 {
    color: #fff;
    width: 100%;
    max-width: 591px;
    margin-top: 21px;
  }

  & > p {
    color: #fff;
    width: 100%;
    max-width: 363px;
    margin-top: 15px;
  }

  & > .ab-link {
    color: #fff;
    border-color: #fff;
    margin-top: 80px;
    position: relative;
    z-index: 99;
  }

  & > span {
    color: #fff;
    width: 100%;
    text-align: center;
    color: #f9f6ef;
    margin-top: 25px;
    font-size: 10pt;
    @media screen and (max-width: $tablet-breakpoint) {
      font-size: 9pt;
    }
  }
}
